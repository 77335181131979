<template>
  <main class="rankings">
    <h1>Team Rankings</h1>
    <Tabs :tabs="regions" v-model="region" @change="tabClick"></Tabs>
    <TabItems v-model="region" :tabs="regions">
      <template slot="global">
        <section class="list">
          <router-link 
            v-for="(n, i) in this.teams.global" 
            :key="'t-global' + i"
            :to="{ name: 'Team', params: { team: n._id, game } }"
            v-slot="{ navigate }" custom
          >
            <div class="place" @click="navigate">
              <span class="position">{{ i + 1 }}.</span>
              <span class="progress">
                <img :src="rankingIcon(n.progress)" />
              </span>
              <span class="name">{{ n.name }}</span>
              <span class="rating">{{ n.rating }}</span>
            </div>
          </router-link>
        </section>
      </template>
      <template slot="eu">
        <section class="list">
          <router-link 
            v-for="(n, i) in this.teams.eu" 
            :key="'t-eu' + i"
            :to="{ name: 'Team', params: { team: n._id, game } }"
            v-slot="{ navigate }" custom
          >
            <div class="place" @click="navigate">
              <span class="position">{{ i + 1 }}.</span>
              <span class="progress">
                <img :src="rankingIcon(n.progress)" />
              </span>
              <span class="name">{{ n.name }}</span>
              <span class="rating">{{ n.rating }}</span>
            </div>
          </router-link>
        </section>
      </template>
      <template slot="na">
        <section class="list">
          <router-link 
            v-for="(n, i) in this.teams.na" 
            :key="'t-na' + i"
            :to="{ name: 'Team', params: { team: n._id, game } }"
            v-slot="{ navigate }" custom
          >
            <div class="place" @click="navigate">
              <span class="position">{{ i + 1 }}.</span>
              <span class="progress">
                <img :src="rankingIcon(n.progress)" />
              </span>
              <span class="name">{{ n.name }}</span>
              <span class="rating">{{ n.rating }}</span>
            </div>
          </router-link>
        </section>
      </template>
      <template slot="sa">
        <section class="list">
          <router-link 
            v-for="(n, i) in this.teams.sa" 
            :key="'t-sa' + i"
            :to="{ name: 'Team', params: { team: n._id, game } }"
            v-slot="{ navigate }" custom
          >
            <div class="place" @click="navigate">
              <span class="position">{{ i + 1 }}.</span>
              <span class="progress">
                <img :src="rankingIcon(n.progress)" />
              </span>
              <span class="name">{{ n.name }}</span>
              <span class="rating">{{ n.rating }}</span>
            </div>
          </router-link>
        </section>
      </template>
      <template slot="as">
        <section class="list">
          <router-link 
            v-for="(n, i) in this.teams.as" 
            :key="'t-as' + i"
            :to="{ name: 'Team', params: { team: n._id, game } }"
            v-slot="{ navigate }" custom
          >
            <div class="place" @click="navigate">
              <span class="position">{{ i + 1 }}.</span>
              <span class="progress">
                <img :src="rankingIcon(n.progress)" />
              </span>
              <span class="name">{{ n.name }}</span>
              <span class="rating">{{ n.rating }}</span>
            </div>
          </router-link>
        </section>
      </template>
    </TabItems>
  </main>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    regions: [
      {
        name: "global",
        label: "Global",
      },
      {
        name: "eu",
        label: "EU",
      },
      {
        name: "na",
        label: "NA",
      },
      {
        name: "sa",
        label: "SA",
      },
      {
        name: "as",
        label: "AS",
      },
    ],
    region: "global",
    teams: {
      global: [],
      eu: [],
      na: [],
      sa: [],
      as: [],
    },
  }),
  computed: {
    game() {
      return this.$store.state.game;
    }
  },
  created() {
    this.regions.map(r => r.name).forEach(region => {
      console.log(region);
      axios.get(this.$api(`/teams/rankings/${region}`)).then(response => {
        this.$set(this.teams, region, response.data.docs);
      });
    });
  },
  methods: {
    tabClick(tab) {
      this.$router.push({ query: { tab } });
    },
    rankingIcon(type) {
      switch (type) {
        case "up":
          return "/icons/ranking/up.svg";
        case "none":
          return "/icons/ranking/none.svg";
        case "down":
          return "/icons/ranking/down.svg";
      }
    },
  },
};
</script>