var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"rankings"},[_c('h1',[_vm._v("Team Rankings")]),_c('Tabs',{attrs:{"tabs":_vm.regions},on:{"change":_vm.tabClick},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('TabItems',{attrs:{"tabs":_vm.regions},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}},[_c('template',{slot:"global"},[_c('section',{staticClass:"list"},_vm._l((this.teams.global),function(n,i){return _c('router-link',{key:'t-global' + i,attrs:{"to":{ name: 'Team', params: { team: n._id, game: _vm.game } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"place",on:{"click":navigate}},[_c('span',{staticClass:"position"},[_vm._v(_vm._s(i + 1)+".")]),_c('span',{staticClass:"progress"},[_c('img',{attrs:{"src":_vm.rankingIcon(n.progress)}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))]),_c('span',{staticClass:"rating"},[_vm._v(_vm._s(n.rating))])])]}}],null,true)})}),1)]),_c('template',{slot:"eu"},[_c('section',{staticClass:"list"},_vm._l((this.teams.eu),function(n,i){return _c('router-link',{key:'t-eu' + i,attrs:{"to":{ name: 'Team', params: { team: n._id, game: _vm.game } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"place",on:{"click":navigate}},[_c('span',{staticClass:"position"},[_vm._v(_vm._s(i + 1)+".")]),_c('span',{staticClass:"progress"},[_c('img',{attrs:{"src":_vm.rankingIcon(n.progress)}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))]),_c('span',{staticClass:"rating"},[_vm._v(_vm._s(n.rating))])])]}}],null,true)})}),1)]),_c('template',{slot:"na"},[_c('section',{staticClass:"list"},_vm._l((this.teams.na),function(n,i){return _c('router-link',{key:'t-na' + i,attrs:{"to":{ name: 'Team', params: { team: n._id, game: _vm.game } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"place",on:{"click":navigate}},[_c('span',{staticClass:"position"},[_vm._v(_vm._s(i + 1)+".")]),_c('span',{staticClass:"progress"},[_c('img',{attrs:{"src":_vm.rankingIcon(n.progress)}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))]),_c('span',{staticClass:"rating"},[_vm._v(_vm._s(n.rating))])])]}}],null,true)})}),1)]),_c('template',{slot:"sa"},[_c('section',{staticClass:"list"},_vm._l((this.teams.sa),function(n,i){return _c('router-link',{key:'t-sa' + i,attrs:{"to":{ name: 'Team', params: { team: n._id, game: _vm.game } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"place",on:{"click":navigate}},[_c('span',{staticClass:"position"},[_vm._v(_vm._s(i + 1)+".")]),_c('span',{staticClass:"progress"},[_c('img',{attrs:{"src":_vm.rankingIcon(n.progress)}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))]),_c('span',{staticClass:"rating"},[_vm._v(_vm._s(n.rating))])])]}}],null,true)})}),1)]),_c('template',{slot:"as"},[_c('section',{staticClass:"list"},_vm._l((this.teams.as),function(n,i){return _c('router-link',{key:'t-as' + i,attrs:{"to":{ name: 'Team', params: { team: n._id, game: _vm.game } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"place",on:{"click":navigate}},[_c('span',{staticClass:"position"},[_vm._v(_vm._s(i + 1)+".")]),_c('span',{staticClass:"progress"},[_c('img',{attrs:{"src":_vm.rankingIcon(n.progress)}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(n.name))]),_c('span',{staticClass:"rating"},[_vm._v(_vm._s(n.rating))])])]}}],null,true)})}),1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }